.language-display {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.edit-icon {
    margin-left: 8px;
    opacity: 0;
    transition: opacity 0.3s;
}

.language-display:hover .edit-icon {
    opacity: 1;
}